
<template>
  <div id="table-demo">
    <vx-card :title="title">
      <vs-table
      multiple
      v-model="selected"
      @dblSelection="markAsRead"
      pagination
      :max-items="6"
      search
      :data="notifications"
      >
        <template slot="header">
          <!-- ACTION - DROPDOWN -->
          <vs-dropdown vs-trigger-click class="cursor-pointer mr-4">
            <div class="p-3 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium">
              <!-- <span class="mr-2"s>Actions</span> -->
              <feather-icon icon="MoreVerticalIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click.prevent="deleteNotifications()">
                <span class="flex items-center">
                  <span>Delete</span>
                </span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </template>
        <template slot="thead">
            <vs-th>Title</vs-th>
            <vs-th>Message</vs-th>
            <vs-th>Date</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr"  :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.title">
                 <span>{{tr.title}} </span> <span v-if="!tr.is_seen" class="rounded-lg px-1 inline vs-chip-success con-color bg-chip-transparent">new</span>
              </vs-td>
              <vs-td :data="tr.text">
                  {{tr.text | truncate(70)}}
              </vs-td>
              <vs-td :data="tr.created_at">
                  {{tr.created_at | date_ago}}
              </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
  </div>
</template>

<script>

export default{
  data() {
    return {
      title: 'Received notifications',
      selected:[],
    }
  },
  computed:{
    notifications() {
      return this.$store.state.notification.notifications
    },
  },
  methods: {
    fetchNotifications() {
      this.$store.dispatch("notification/fetchNotifications")
    },
    deleteNotifications(){
      if(!this.selected.length){ this.alertError("Please select an item"); return}
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Are you sure?',
        text: `You are about to delete ${this.selected.length} notification${this.selected.length>1 ? 's': ''}`,
        accept: this.acceptDelete
      })
    },
    acceptDelete(){
      let selectedIds = this.selected.map((i)=> i.id);
      const formData = new FormData()
      formData.append('data', JSON.stringify({items: selectedIds}))
      this.$http.post("/notifications/delete", formData)
      .then((response) => {
        if(response.data.success){
          this.alertSuccess(response.data.success)
          this.fetchNotifications()
        }else{
          this.alertError(response.data.error)
        }
      }).catch((error)=>{console.log(error)})
    },
    markAsRead(notif) {
       if(!notif.is_seen){
        this.$store.dispatch("notification/markNotifAsRead", notif.id);
      }
      this.$router.push(notif.url).catch(() => {});
    }
  }
}
</script>

<style type="text/css">
[dir] .vx-card .vx-card__collapsible-content .vx-card__body {
    padding-top: .5rem !important;
}
#table-demo .unread-bg{
  background-color: #d3d3d3;
}
</style>
